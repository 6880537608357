@import "../../../bps/components/consentCookieBox/consentCookieBoxLightBox.scss";

.consent-cookie-box-light-box {
  &--native-app {
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100vh;
    z-index: $indexAppCookieLightBox;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .consent-cookie-box-light-box__logo-container {
      position: relative;
      box-shadow: 0 -16px 16px #0002;
      padding-top: 24px;

      img {
        width: 160px;
      }

      @media (min-height: 730px) {
        &::before {
          content: '';
          display: block;
          background: url(../staticImages/heart-finger.png) no-repeat center center;
          background-size: contain;
          width: 100%;
          height: 150px;
          position: absolute;
          top: 110px;
        }

        &::after {
          content: '';
          display: block;
          height: 110px;
          width: 100%;
          background: $redDarkColor;
          margin: 32px 0 40px;
        }
      }
    }

    .consent-cookie-box__headline {
      font-weight: $fontWeightMedium;
    }

    .consent-cookie-box__text {
      font-size: 16px;
    }
  }
}
