@import "../../../bps/components/consentCookieBox/consentCookieBox.scss";

.consent-cookie-box {
  .button {
    margin-bottom: 16px;

    @media (min-width: $screen-desktop) {
      margin-bottom: 8px;
    }
  }

  &__button {
    &-reject-all {
      text-transform: none;
      text-decoration: underline;
      color: transparentize($grayDarkColor, .3);
      text-align: center;
      margin-bottom: 8px;
      letter-spacing: 0;
      font-weight: $fontWeightRegular;
      background: none;
      border: 0;
      transition: all .2s;
      font-size: $buttonFontSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $buttonFontSizeDesktop;
      }

      body:not(.is_touch) & {
        &:hover {
          background: none;
          color: $grayDarkColor;
          text-decoration: underline;
          box-shadow: none;
        }
      }
    }
  }

  &__button-config--disabled {
    opacity: .5;
    cursor: not-allowed;

    &:hover {
      background: inherit;
      color: inherit;
      border-color: inherit;
    }

    .loader {
      display: block;
      width: 35px;
      top: 0;
    }
  }

  &__button-accept-all--disabled {
    opacity: .5;
    cursor: not-allowed;
    display: flex;

    &:hover {
      background: $goldColor;
      color: $textLightColor;
      border: 1px solid $goldColor;
    }

    .loader {
      display: block;
      width: 35px;
    }
  }

  &__button-config {
    text-transform: none;
    text-decoration: underline;
    color: transparentize($grayDarkColor, .3);
  }

  &__text {
    font-size: $fontSizeSmall;
  }
}
